import React from 'react'
import './Footer.css'
import {FaHome, FaPhone,FaMailBulk, FaFacebook, FaTwitter, FaLinkedin} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <div className='left'>
                <div className='location'>
                    <FaHome size={20} style={{color:"white",
                    marginRight: "2rem"}}/>
                    <div>
                        <p>House#25-B New Mehrban Colony</p>
                        <p>MDA Chowk,Multan</p>
                    </div>
                </div>
                <div className='phone'>
                    <h4>
                    <FaPhone size={20} style={{color:"white",
                    marginRight: "2rem"}}/>
                    +92302-2736698
                    </h4>
                </div>
                <div className='email'>
                    <h4>
                    <FaMailBulk size={20} style={{color:"white",
                    marginRight: "2rem"}}/>
                    abdm62256@gmail.com
                    </h4>
                </div>
            </div>
            <div className='right'>
                <h4>About me</h4>
                <p>
                    This is me Abdullah Malik. The ReactJs & Wordpress Developer.
                    i enjoy discussing new projects and design challenges with clients. 
                </p>
                <div className='social'>
                    <a 
                    href = "https://www.facebook.com/abdullahasif123" target='_blank'>
                <FaFacebook size={30} style={{color:"white",
                    marginRight: "1rem"}}/>
                    </a>
                <a 
                href='https://twitter.com/Abdulla80419355' target='_blank'>
                <FaTwitter size={30} style={{color:"white",
                    marginRight: "1rem"}}/>
                </a>  

                <a href='https://www.linkedin.com/in/abdullah-malik-42b54622a/' target='_blank'>
                <FaLinkedin size={30} style={{color:"white",
                    marginRight: "1rem"}}/>
                </a>

                </div>
            </div>
        </div>
        </div>
  )
}

export default Footer