import image1 from'../../assests/client1.jpg'
import image2 from'../../assests/client2.jpg'
import image3 from'../../assests/client3.jpg'

export const testimonialsData = [
    {
      image: image1,
      review:
        "Abdullah helped set the initial needs clear before starting on the order. Even though the request was a bit complex he delivered on what was needed. Communication was clear and fast. I can definitely recommend working with him!",
      name: 'BRAMVER CAMMEN',
      status : 'Netherland'
    },
    {
      image: image2,
      review: 'Very professional and delivered quality service.Highly recomeded to work with him. Thank you so much!!',
      name: 'SIMONY YUS',
      status: 'United States'
    },
    {
      image : image3,
      review:' Amazing work, communication is excellent and quality of work is out standing. He has a good understanding of a reactjs.',
      name: 'Ema Villay',
      status: "Ireland"
    }
  ];