import pro1 from '../../assests/project1.jpg'
import pro2 from '../../assests/Project2.jpg'
import pro3 from '../../assests/Project3.jpg'
const ProjectCardData = [
    {
    imgsrc: pro1,
    title:"Business Website Design ",
    text: "Thriving Facebook community and top-ranked podcasts , Amy inspires a grounded, tangible and self affirming sense of 'WOW!  I really can do this' for over 100,000 online enterprenours. Best websites to increase your business ",
    view: "https://fitclubabd.pages.dev/"
      },

      {
        imgsrc: pro2,
        title:"Real Estate Websites ",
        text: "Thriving Facebook community and top-ranked podcasts , Amy inspires a grounded, tangible and self affirming sense of 'WOW!  I really can do this' for over 100,000 online enterprenours. Best websites to increase your businesses ",
        view: "url.com"},

          {
            imgsrc: pro3,
            title:"Business Protfolio Website ",
            text: "Thriving Facebook community and top-ranked podcasts , Amy inspires a grounded, tangible and self affirming sense of 'WOW!  I really can do this' for over 100,000 online enterprenours. Best  portfolio websites for you ",
            view: "url.com"
              }

]
export default ProjectCardData;
