import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import HeroImg from'../components/HeroImg/HeroImg';
import Footer from '../components/Footer/Footer';
import Work from '../components/WorkCard/Work';
import Testimonials from '../components/Testimonials/Testimonials';

const Home = () => {
  return (
    <div>
      <Navbar/>
      <HeroImg/>
      <Work/>
      <Testimonials/>
      <Footer/>
    </div>
  )
}

export default Home