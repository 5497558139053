import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import HeroImg2 from '../components/HeroImg2/HeroImg2';
import PricingCard from '../components/PricingCard/PricingCard';
import Work from '../components/WorkCard/Work';

const Projects = () => {
  return (
    <div>
      <Navbar/>
      <HeroImg2 heading="PROJECTS." text="Some of my most recent works"/>
      <Work/>
      <PricingCard/>
      <Footer/>  
    </div>
  )
}

export default Projects