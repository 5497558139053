import { Link } from 'react-router-dom'
import './AboutContent.css'
import React from 'react'
const AboutContent = () => {
  return (
    <div className='about'>
        <div className='left'>
            <h1>Who Am I?</h1>
            <p>
                I am a friendly front-end developer specializing in modern
                technologies such as React, Node js, and many more. My passion lies in building
                responsive websites that help to improve user experience and grow your business.
                 I create responsive secure websites for my clients.
            </p>
            <Link to="/contact">
             <button className='btn'>Contact Me</button> 
            </Link>

        </div>

    </div>
  );
};

export default AboutContent