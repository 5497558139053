import './HeroImg.css'
import React from 'react'
import IntroImg from '../../assests/fili-santillan-qp51FQhBnS0-unsplash.jpg'
import {Link} from "react-router-dom"

const HeroImg = () => {
  return (
    <div className='hero'>
        <div className='mask'>
            <img className='intro-img' src={IntroImg}
             alt="IntroImg"/>
        </div>
        <div className='content'>
            <p>HI, MY NAME IS ABDULLAH MALIK</p>
            <h1>I am a React Developer.</h1>
            <div>
                <Link to="/projects" className='btn'>
                    Projects
                </Link>
                <Link to="/contact" className='btn btn-light'>
                    Contact
                </Link>
            </div>

        </div>

    </div>
  )
}

export default HeroImg